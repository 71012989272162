<template>
  <div v-if="channels.length > 0">
    <PageHeader :logo="productCategory" />
    <GridHolder class="header-margin">
      <ChannelGrid :channels="channels" :shop-items="shopItems" :paper-items="paperItems" />
    </GridHolder>
  </div>
</template>

<script>
import getChannelData from '@/components/channel/getChannelData'

import PageHeader from '@/components/header/default'
import GridHolder from '@/components/ui/grid/grid'
import ChannelGrid from '@/components/channel/channel-grid'

import { generateTitle, generateMetaTags } from '@/utils/head-generators'

export default {
  components: {
    GridHolder,
    ChannelGrid,
    PageHeader,
  },
  layout: 'channel',
  asyncData(params) {
    return getChannelData(params)
  },
  data() {
    return {
      channels: [],
      shopItems: [],
      paperItems: [],
    }
  },
  head() {
    return {
      title: generateTitle(
        this.channels[0].attributes.pageTitle ? this.channels[0].attributes.pageTitle : '',
        this.$config.pageTitle
      ),
      meta: generateMetaTags(
        this.$config.pageTitle,
        this.$config.baseUrl,
        this.$config.imageServiceBaseUrls,
        this.channels[0].attributes.pageTitle ? this.channels[0].attributes.pageTitle : '',
        this.channels[0].attributes.description ? this.channels[0].attributes.description : '',
        this.$route.path,
        '/og-default.jpg',
        100,
        100
      ),
    }
  },
  computed: {
    productCategory() {
      if (this.channels[0].attributes.options && this.channels[0].attributes.options.category !== null) {
        return this.channels[0].attributes.options.category.toLowerCase()
      } else {
        return 'default'
      }
    },
  },
}
</script>
